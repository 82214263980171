import OneYearGuarantee from "components/YearGuarantee";

import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const YearGuarantee = () => {
  const description =
    "Enjoy peace of mind with our 1-year quality guarantee on applicable language services. Learn more about our enduring commitment to quality on our policy page.";
  const title = "Our Commitment: 1-Year Quality Guarantee";

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/about/1-year-guarantee"
      />
      <OneYearGuarantee />
    </Layout>
  );
};
export default YearGuarantee;
